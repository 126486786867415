.wrapper {
  background: #1D3444;
}

::-webkit-scrollbar {
  display: none;
}

.unfoldIcon {
  width: 24px;
  height: 21px;
  padding-top: 58px;
  padding-left: 16px;
  padding-bottom: 26px;
}

.log-wrapper {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.log {
  width: 98.98px;
  height: 35.714px;
  padding-top: 51px;
  padding-bottom: 18.29px;
}

.search {
  width: 40px;
  height: 40px;
  padding-top: 49px;
  padding-right: 16px;
}

.showMenu {
  width: 100%;
  height: 100vh;
  background: #000;
  overflow: auto;
}

.close {
  width: 18px;
  height: 18px;
  padding-top: 59px;
  padding-left: 16px;
  flex-grow: 1;
}

.logOut {
  display: inline-flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.10);
  color: #FFF;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  width: 108px;
  height: 40px;
  border: 0px;
}

.iconLocation {
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-top: 32px;
  margin-bottom: 40px;
}

.icon {
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  justify-content: center;
  opacity: 0.5;
}

.iconNameActive {
  color: #FFF;
  font-family: Museo-700;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-left: 30px;
  opacity: 1;
}

.iconName {
  color: #FFF;
  font-family: Museo-700;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-left: 30px;
  opacity: 0.5;
}